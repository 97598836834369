<template>
  <div class="country-card" @click="onViewBanner">
    <img :src="flagImage"  loading="lazy" @error="setAltImg" style="width:80px;height:75%px;" />
    <div class="content">
      <img :src="require('@/assets/images/COUNTRY.png')" class="name-image" loading="lazy" />
      <h4 v-if="data?.CountryName && data?.CountryName != ''">
        {{ data.CountryName }}
      </h4>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from 'vue'
import { get } from '@/utils/axios'
//import CountryFlag from 'vue-country-flag-next'
import { f7 } from "framework7-vue";
import { findFlagUrlByIso2Code,findFlagUrlByIso3Code } from "country-flags-svg";



export default defineComponent({
  name: 'CountryCardComponent',
  components: {
    // CountryFlag
  },
  props: {
    data: Object
  },
  setup(props) {
    const $f7router = f7.views.get("#main-view").router;
    //const flagImage = ref(`https://countryflagsapi.com/png/${props?.data?.CountryCode}`)
    //const country = ref(``);
    const flagImage = ref(null);
   

    onMounted(async() => {
      let flagUrl = findFlagUrlByIso2Code(props?.data?.CountryCode);
      if(props?.data.CountryCode.length > 2){
        flagUrl = findFlagUrlByIso3Code(props?.data?.CountryCode)
      }
      flagImage.value = flagUrl;
    });
   
    const onViewBanner = () => {
      $f7router.navigate({ name: 'countryViewPage', params: { code: props?.data?.CountryCode } })
      // if (props?.data?.Link) {
      //   if (props?.data?.Link.indexOf("http") > -1) {
      //    helpers.deepLinking($f7router, props?.data?.Link);
      //   } else {
      //     $f7router.navigate({ name: "productViewPage", params: { code: props?.data?.Link } });
      //   }
      // }
    }
    const setAltImg = (event) => { 
        event.target.src = require('@/assets/images/_pour-wine.png')
    } 
    return { onViewBanner, flagImage, setAltImg }
  }
})
</script>
